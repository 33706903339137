    /* HTML: <div class="loader"></div> */

    .progress-spinner {
      position: fixed;
      z-index: 99999;
      overflow: show;
      margin: auto;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .progress-spinner:before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(143, 143, 143, 0.53);
  }
  
  
  /* .swm-loader {
      width: 50px;
      --b: 8px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: #514b82;
      -webkit-mask:
          repeating-conic-gradient(#0000 0deg, #000 1deg 70deg, #0000 71deg 90deg),
          radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
      -webkit-mask-composite: destination-in;
      mask-composite: intersect;
      animation: l5 1s infinite;
  }
  
  @keyframes l5 {
      to {
          transform: rotate(.5turn)
      }
  } */
  
  .swm-loader {
      width: 40px;
      height: 40px;
      --c: no-repeat linear-gradient(#292b39 0 0);
      background: var(--c), var(--c), var(--c), var(--c);
      background-size: 21px 21px;
      animation: l5 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
  }
  
  @keyframes l5 {
      0% {
          background-position: 0 0, 100% 0, 100% 100%, 0 100%
      }
  
      33% {
          background-position: 0 0, 100% 0, 100% 100%, 0 100%;
          width: 60px;
          height: 60px
      }
  
      66% {
          background-position: 100% 0, 100% 100%, 0 100%, 0 0;
          width: 60px;
          height: 60px
      }
  
      100% {
          background-position: 100% 0, 100% 100%, 0 100%, 0 0
      }
  }